<template>
  <div>
    <!-- 按钮列表 -->
    <!-- <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="saveBtns">保存更新</el-button>
    </div> -->

    <div class="tips">提示：只支持16进制的色值，例如：#FFFFFF , #000000</div>

    <!-- 主题色 -->
    <div class="listItmes">
      <div class="titles">会员端主题色设置:</div>
      <el-button
        class="itemBtn btnColor"
        @click="saveBtns('zhu_ti:theme_type', number1)"
        >保存主题色</el-button
      >
      <div class="block">
        <span class="demonstration">主题色值</span>
        <el-color-picker v-model="color1"></el-color-picker>
        <input
          style="margin-left: 10px"
          placeholder="请输入"
          type="text"
          v-model="color1"
        />
      </div>
    </div>

    <!-- 会员端首页背景渐变色 -->
    <div class="listItmes">
      <div class="titles">会员端首页背景色设置:</div>
      <el-button
        class="itemBtn btnColor"
        @click="saveBtns('bei_jing:theme_type', number2)"
        >保存背景色</el-button
      >
      <div class="directionS">
        <div>是否渐变:</div>
        <el-select
          class="selectItems"
          v-model="colorsType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in isGradient"
            :key="item.name"
            :label="item.name"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </div>

      <div class="block">
        <span class="demonstration" v-if="colorsType == 'chun_se:colors_type'"
          >背景色值</span
        >
        <span class="demonstration" v-if="colorsType == 'jian_bian:colors_typ'"
          >开始色值</span
        >
        <el-color-picker v-model="color2"></el-color-picker>
        <input
          style="margin-left: 10px"
          placeholder="请输入"
          type="text"
          v-model="color2"
        />
      </div>
      <div class="block" v-if="colorsType == 'jian_bian:colors_typ'">
        <span class="demonstration">结束色值</span>
        <el-color-picker v-model="color3"></el-color-picker>
        <input
          style="margin-left: 10px"
          placeholder="请输入"
          type="text"
          v-model="color3"
        />
      </div>

      <div class="directionS" v-if="colorsType == 'jian_bian:colors_typ'">
        <div>渐变方向:</div>
        <el-select
          class="selectItems"
          v-model="gradientDirection"
          placeholder="请选择"
        >
          <el-option
            v-for="item in GradientDirection"
            :key="item.name"
            :label="item.name"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </div>
      <div
        v-if="colorsType == 'jian_bian:colors_typ'"
        class="testBOx"
        :style="
          'background-image: linear-gradient(' +
          gradientDirection +
          ', ' +
          color2 +
          ', ' +
          color3 +
          ')'
        "
      >
        渐变色示例
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from "jsbarcode";
export default {
  data() {
    return {
      color1: "#f9087c",
      color2: "#f9087c",
      color3: "#f8ebf1",
      gradientDirection: "to bottom", //渐变方向
      colorsType: "jian_bian:colors_typ", //颜色类型 渐变、纯色
      isGradient: [
        {
          name: "渐变色",
          val: "jian_bian:colors_typ",
        },
        {
          name: "纯色",
          val: "chun_se:colors_type",
        },
      ],
      GradientDirection: [
        {
          name: "上到下",
          val: "to bottom",
        },
        {
          name: "下到上",
          val: "to top",
        },
        {
          name: "左到右",
          val: "to right",
        },
        {
          name: "右到左",
          val: "to left",
        },
        {
          name: "左上到下右",
          val: "to bottom right",
        },
        {
          name: "右上到坐下",
          val: "to bottom left",
        },
      ],
      number1: "c001",
      number2: "c002",
    };
  },
  watch: {
    colorsType: function (a, b) {
      console.log(a);
      if (a == "chun_se:colors_type") {
        this.color3 = "";
      }
    },
  },
  created() {},
  mounted() {
    this.getThemeList(this.number1);
    this.getThemeList(this.number2);
  },
  activated() {},
  destroyed() {},
  methods: {
    getThemeList(number) {
      this.$API
        .getThemeList({
          number: number,
        })
        .then((res) => {
          let datas = res.data.result || [];
          if (datas.length == 0) {
            return false;
          }
          if (number == this.number1) {
            // 主题
            this.color1 = datas[0].colors;
          } else {
            // 背景色
            let arrs = datas[0].colors.split(",") || [];
            this.color2 = arrs.length > 0 ? arrs[0] : "";
            this.color3 = arrs.length > 1 ? arrs[1] : "";
          }
        });
    },
    // 保存
    saveBtns(themeType, number) {
      let themeName =
        themeType == "zhu_ti:theme_type" ? "主题色" : "首页背景色";
      let colors = "";

      let gradientDirection = JSON.parse(
        JSON.stringify(this.gradientDirection)
      );
      if (themeType == "zhu_ti:theme_type") {
        // 主题色
        colors = this.color1;
        gradientDirection = "";
      } else {
        // 首页背景色
        colors = this.color2 + "," + this.color3;
      }
      let param = {
        number: number,
        colors: colors,
        colorsType: this.colorsType,
        deviceType: "member_h5:device_type",
        gradientDirection: gradientDirection,
        isShow: 1, //是否展示 0=否 1=是
        remark: "",
        themeName: themeName,
        themeType: themeType,
      };
      param.sign = this.Tools.getSign(param);
      this.$API.saveTheme(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");

        if (number == this.number1) {
          this.getThemeList(this.number1);
        }
        if (number == this.number2) {
          this.getThemeList(this.number2);
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
$borderColor: #666;

body {
  background-color: #fff;
}
/deep/ .el-input__inner {
  border: 1px solid $borderColor;
}
.listItmes {
  margin-bottom: 50px;
  padding-bottom: 10px;
  border-bottom: 1px dashed $borderColor;
}

.block {
  display: flex;
  align-items: center;
}
.demonstration {
  margin-right: 10px;
}

.btnColor {
  background-color: #f0a261;
  border: none;
  color: #fff;
  padding: 10px !important;
}

.testBOx {
  width: 200px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  //   background-image: linear-gradient(to bottom, red, yellow);
}

.tips {
  font-size: 13px;
  color: #999;
  margin: 30px 0;
}

.directionS {
  display: flex;
  align-items: center;

  .selectItems {
    width: 225px;
    margin: 10px;
  }
}

.titles {
  font-weight: bold;
  padding: 0;
  margin: 10px 0;
}
</style>